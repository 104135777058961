.needed-today {
  &__svg-wrapper {
    min-width: 48px;
    min-height: 48px;
    display: flex;
    align-items: center;
  }

  &-quantity {
    text-align: left;
  }
  
  .text-group {
    max-width: 62px;
    margin-left: 4px;
  }
}