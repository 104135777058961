/**
* ==============================================
* Buttons
* ==============================================
*/
@import "./variables.scss";

.btn {
  padding: 9px 20px;
  border-radius: 999px;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  display: flex;
  justify-items: center;
}
.btn-sm {
  padding: 6px 20px;
  font-size: 13px;
}
.btn-white {
  color: $primayBlue;
  border: 1px solid $primayBlue;
  &:hover {
    background-color: $whiteHover;
  }
}
.btn-white-shade {
  color: $black;
  border: 1px solid $primayBlue;
  background-color: $whiteHover;
  white-space:nowrap;
  &:hover {
    background-color: #dde4eb;
  }
}
.btn-primayBlue {
  color: $white;
  border: 1px solid $primayBlue;
  background-color: $primayBlue;
  &:hover {
    color: $white;
    background-color: #4670e3;
  }
}

.btn-disabled:not(.link-only) {
  background-color: $gray1;
  border-color: $gray1;
  cursor: not-allowed;
  color: $gray6;
  &:hover {
    color: $gray6;
    background-color: $gray1;
    border-color: $gray1;
  }
}


.btn-disabled.link-only {
  cursor: not-allowed;
  color: $gray6;
  &:hover {
    color: $gray6;
  }
}