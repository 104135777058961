/**
* ==============================================
* Shopping Table
* ==============================================
*/
@import "./base.scss";

$standardQuantitiesWidth: 102px;
$dispensedItemQuantitiesWidth: 242px;
$overallQuantityQuantitiesWidth: 130px;
$rxNumberQuantitiesWidth: 83px;

table {
  tr {
    position: relative;
  }
  td {
    height: 100%;
    > div > div {
      height: auto;
    }
  }

  .inventory-item {
    padding-left: 4px;
    position: relative;
    width: 100px;
    background-color: $gray2;
    height: 24px;
    line-height: 24px;
    color: #fff;
    letter-spacing: 0;
    &::after {
      position: absolute;
      content: "";
      right: -10px;
      top: 0;
      width: 0;
      height: 0;
      border-top: 12px solid transparent;
      border-left: 10px solid $gray2;
      border-bottom: 12px solid transparent;
    }
  }
}

.filter-group {
  width: 952px;
}

.MuiBox-root {
  .filter-group {
    width: 942px;
  }
}

@keyframes sheen {
  0% {
    box-shadow: inset 0 0 1px 2px #f3f7fb;
  }
  40% {
    box-shadow: inset 0 0 1px 2px #324dc7;
  }
  100% {
    box-shadow: inset 0 0 1px 2px transparent;
  }
}

.suppliers-list {
  width: 100%;
  margin-top: 24px;

  >tbody>tr {
    border-top: 1px solid $gray1;

    &:hover {
      background-color: $BlueTint1;
    }

    td {
      padding: 6px 12px;

      &:nth-of-type(1) {
        width: 180px;
        min-width: 180px;
        max-width: 180px;
      }

      &:nth-of-type(3) {
        width: 80px;
        max-width: 80px;
      }
    }

    td.cutOff-time {
      padding-left: 0;
      padding-right: 0;
    }

    &:last-of-type {
      border-bottom: 1px solid $gray1;
    }
  }

  >tbody>tr.inline-addition {
    border-top: 0;
  }

  td {
    .name {
      color: #5c5c5c;
      font-size: 16px;
      font-weight: 700;
    }
    .name.linklike {
      color: $primayBlue;
      text-decoration: underline;
    }
  }
}

// ------------- CutOff Items
.cutoff {
  display: flex;
  align-items: center;
  border-top: 1px solid $gray8;
  padding: 6px;
  .title {
    font-weight: 400;
    font-size: 14px;
    width: 65px;
  }
  .value {
    font-weight: 300;
    font-size: 14px;
  }
}

// Page 3
.buying-options-container {
  font-family: Roboto, sans-serif;

  .supplier-block {
    display: flex;
    justify-content: space-between;
  }

  .or-wrapper {
    position: relative;
    &::before {
      content: "";
      display: block;
      height: 38px;
      width: 1px;
      background-color: #979797;
      position: absolute;
      left: 10px;
      top: -44px;
    }
    &::after {
      content: "";
      display: block;
      height: 38px;
      width: 1px;
      background-color: #979797;
      position: absolute;
      left: 10px;
      top: 27px;
    }
    .or {
      font-weight: 700;
      color: rgba(165, 165, 165, 0.803921568627451);
      position: relative;
      &::before {
        content: "";
        display: block;
        height: 1px;
        border-top: 1px solid rgba(165, 165, 165, 0.803921568627451);
        position: absolute;
        left: -16px;
        top: 10px;
        width: 10px;
      }
    }
    .or {
      &::after {
        content: "";
        display: block;
        height: 1px;
        border-top: 1px solid rgba(165, 165, 165, 0.803921568627451);
        position: absolute;
        right: -16px;
        top: 10px;
        width: 10px;
      }
    }
  }
  .green-bar {
    display: inline-block;
    background-color: rgba(37, 148, 82, 1);
    color: #fff;
    font-weight: 700;
    font-size: 14px;
    padding: 8px;
  }
  .loader-light {
    border-radius: 9px;
    border: 1px solid #fff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.27);
    min-width: 460px;
    min-height: 260px;
    margin-top: 64px;
  }
  .suppliers-info {
    font-size: 14px;
    line-height: 16px;
    margin-top: 16px;
    padding-right: 32px;
    + .suppliers-info {
      margin-top: 8px;
    }
  }

  .savings-wrapper {
    min-width: 300px;
  }
}

.tbl-tab-container {
  .MuiButtonBase-root.MuiTab-root {
    text-transform: none;
  }

  .MuiTabs-flexContainer {
    .MuiTab-textColorPrimary {
      color: $black;
      padding-bottom: 0px;
      line-height: 1em;
      &.Mui-selected {
        color: $black;
      }
    }
  }

  .MuiTabs-indicator {
    background-color: $black !important;
  }

  .print {
    &:hover {
      color: $primayBlue;
    }
  }
}

.buying-tbl {
  width: 100%;
  > tbody {
    > tr {
      width: 100%;
      &:not(.nested) {
        cursor: pointer;
        td {
          padding: 15px 10px;
          padding-left: 0;
          > div {
            padding-left: 10px;
          }

          &:nth-of-type(1) {
            border: 1px solid $gray1;
            width: 40px;
            min-width: 40px;
            max-width: 40px;
            padding: 24px 12px 24px 12px;
            background-color: $gray4;
            cursor: pointer;
          }
          &:nth-of-type(2) {
            border: 1px solid $gray1;
            border-right: 0;
            padding: 24px;
          }
          &:nth-of-type(3) {
            border: 1px solid $gray1;
            border-left: 0;
            border-right: 0;
            padding: 24px;
          }
          &:nth-of-type(4) {
            border: 1px solid $gray1;
            border-left: 0;
            border-right: 0;
            padding: 24px;
          }
          &:nth-of-type(5) {
            border: 1px solid $gray1;
            border-left: 0;
            padding: 24px;
          }
        }
      }
      &.nested {
        td {
          position: relative;
          letter-spacing: -0.4px;
          vertical-align: top;
          padding: 14px;
          padding-right: 0;
          border-bottom: 1px solid $gray1;
          &:last-of-type {
            border-right: 1px solid $gray1;
          }
          &:before {
            position: absolute;
            top: 10px;
            display: block;
            height: calc(100% - 20px);
            width: 1px;
            background-color: rgb(209 213 219);
            content: "";
          }
          &.no-devider {
            &::before {
              display: none;
            }
          }
          > div {
            padding-left: 10px;
          }
          &:nth-of-type(1) {
            border-left: 1px solid $gray1;
            background-color: $gray5;
            padding: 0;
            &::before {
              display: none;
            }
            div {
              padding: 0;
              padding-top: 10px;
              display: flex;
              justify-content: center;
            }
            img {
              width: 22px;
            }
          }
          &:nth-of-type(2) {
            width: 350px;
          }
          &:nth-of-type(3) {
            padding-left: 6px;
            width: 100px;
            min-width: 100px;
          }
          &:nth-of-type(4) {
            width: 150px;
            min-width: 150px;
          }
          &:nth-of-type(6) {
            width: 416px;
            padding-right: 16px;
          }
        }
      }
    }
    > tr.nested.tall {
      td {
        &:nth-of-type(6) {
          vertical-align: top;
        }
        &:nth-of-type(7) {
          vertical-align: top;
        }
        &:nth-of-type(8) {
          vertical-align: top;
          padding-top: 8px;
        }
      }
    }

    > tr {
      td {
        img.eye {
          display: none;
        }
      }
      &.seen-item {
        td {
          img.eye {
            display: block;
          }
        }
      }
    }
    > tr.seen-item {
      background-color: #e8e8e8;
    }
  }
}

.rx-tbl {
  thead {
    background-color: #434343;
    color: $white;
    > tr {
      th {
        font-size: 14px;
        height: 100%;
        position: relative;
        letter-spacing: -0.4px;
        min-height: 64px;
        height: 64px;
        &:before {
          position: absolute;
          top: 10px;
          display: block;
          height: calc(100% - 20px);
          width: 1px;
          background-color: rgb(209 213 219);
          content: "";
        }
        &:first-of-type {
          &::before {
            display: none;
          }
        }
        > div {
          padding-left: 10px;
        }

        &:nth-of-type(1) {
          width: 73px;
          min-width: 73px;
          max-width: 73px;
        }
        &:nth-of-type(2) {
          width: 65px;
          min-width: 65px;
          max-width: 65px;
        }
        &:nth-of-type(3) {
          width: 82px;
          min-width: 82px;
          max-width: 82px;
        }
        &:nth-of-type(4) {
          width: 167px;
          min-width: 167px;
          max-width: 167px;
        }
        &:nth-of-type(5) {
          width: 241px;
          min-width: 241px;
          max-width: 241px;
        }
        &:nth-of-type(6) {
          width: 120px;
          min-width: 120px;
          max-width: 120px;
        }
        &:nth-of-type(7) {
          width: 135px;
          min-width: 135px;
          max-width: 135px;
        }
        &:nth-of-type(8) {
          width: 162px;
          min-width: 162px;
          max-width: 162px;
        }
      }
    }
  }
  > tbody {
    > tr {
      position: relative;

      border-bottom: 1px solid rgb(209 213 219);
      td {
        vertical-align: top;
        padding: 8px 0;
        &:before {
          position: absolute;
          top: 10px;
          display: block;
          height: calc(100% - 20px);
          width: 1px;
          background-color: rgb(209 213 219);
          content: "";
        }
        &:first-of-type {
          border-left: 1px solid rgb(209 213 219);
          &::before {
            display: none;
          }
        }
        &:last-of-type {
          border-right: 1px solid rgb(209 213 219);
        }
        > div {
          padding-left: 10px;
        }
      }
      &.rx- > tr {
        td {
          &:nth-of-type(1) {
            width: 73px;
            min-width: 73px;
            max-width: 73px;
          }
          &.is-inv {
            width: auto;
            min-width: auto;
            max-width: auto;
          }
          &:nth-of-type(2) {
            width: 65px;
            min-width: 65px;
            max-width: 65px;
          }
          &:nth-of-type(3) {
            width: 82px;
            min-width: 82px;
            max-width: 82px;
          }
          &:nth-of-type(4) {
            width: 167px;
            min-width: 167px;
            max-width: 167px;
          }
          &:nth-of-type(5) {
            width: 241px;
            min-width: 241px;
            max-width: 241px;
          }
          &:nth-of-type(6) {
            width: 120px;
            min-width: 120px;
            max-width: 120px;
          }
          &:nth-of-type(7) {
            width: 135px;
            min-width: 135px;
            max-width: 135px;
          }
          &:nth-of-type(8) {
            width: 162px;
            min-width: 162px;
            max-width: 162px;
          }
        }
      }
      &.inv- > tr {
        td {
          &:nth-of-type(1) {
            width: 220px;
            min-width: 220px;
            max-width: 220px;
          }
          &:nth-of-type(2) {
            width: 241px;
            min-width: 241px;
            max-width: 241px;
          }
          &:nth-of-type(3) {
            width: 120px;
            min-width: 120px;
            max-width: 120px;
          }
          &:nth-of-type(4) {
            width: 135px;
            min-width: 135px;
            max-width: 135px;
          }
          &:nth-of-type(5) {
            width: 162px;
            min-width: 162px;
          }
        }
      }
    }
  }
}

.rx-input {
  padding: 9px 9px;
  border-radius: 6px;
  border: solid 1px #8d8d8d;
  max-width: 74px;
}

.manual-in-alert {
  padding: 24px;
  border: 1px solid $primayBlue;
  margin-bottom: 24px;
  margin-top: 24px;
  border-radius: 3px;
  p {
    text-align: center;
  }
}

.attention {
  color: #c73232;
}

.substitute-pop {
  background: white;
  border: 1px solid #4654E3;
  border-radius: 3px;
  padding: 24px 30px 32px;
  box-shadow: 0px 0px 12px rgb(0 0 0 / 27%);
  .MuiPaper-root {
    box-shadow: none;
  }
  .title {
    margin: 0 0 9px 2px;
    font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
    // font-s> tretch: condensed;
  }
  label {
    font-size: 14px;
    font-weight: normal;
    font-stretch: condensed;
    // line-height: 1.89;
    + label {
      margin-top: 4px;
    }
  }
  .ex > tra-notes {
    margin-top: 12px;
  }
  .arrow {
    bottom: 0px;
    left: 0px;
    margin-bottom: -1em;
    width: 3em;
    height: 1em;

    color: #fff;
    overflow: hidden;
    position: absolute;
    box-sizing: border-box;
    &::before {
      transform-origin: 100% 0;
      width: 100%;
      height: 100%;
      margin: auto;
      content: "";
      display: block;
      transform: rotate(45deg);
      box-shadow: 0px 2px 1px -1px $primayBlue, 1px 1px 1px 0px $primayBlue,
        0px 1px 3px 0px rgb(0 0 0 / 20%);

      // box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
      background-color: currentColor;
    }
  }
}

// MUI
div[class$="root-MuiAccordion-root"] {
  background: unset;
  border-radius: 0 !important;
  box-shadow: none;
  &::before {
    background-color: unset;
  }
  div[class$="-expandIconWrapper"] {
    transform: none;
  }
}
div[class$="root-MuiAccordionSummary-root"] {
  &:hover {
    cursor: default !important;
  }
}

.shoppingList-acc {
  background: unset;
  border-radius: 0 !important;
  box-shadow: none;
  .MuiAccordionSummary-root {
    // pointer-events: none;
    flex-direction: row-reverse;
    background-color: $BlueTint1;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;

    // padding: 8px 4px;
    display: flex;
    align-items: center;
    &.Mui-expanded {
      min-height: 48px !important;
      cursor: default;
    }
    .pm-icon {
      margin-left: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 16px;
      height: 16px;
      line-height: 16px;
      font-size: 16px;
      font-weight: 300;
      border: 1px solid #0a1e79;

      color: $white;
      background-color: #0a1e79;
      &.open {
        color: #0a1e79;
        background-color: $BlueTint1;
      }
    }
    .Mui-expanded {
      margin: unset;
    }
    .MuiAccordionSummary-content {
      color: #0a1e79;
      text-align: center;
      flex-grow: 1;
      display: flex;

      margin: 12px 0 !important;
    }
  }
  .MuiCollapse-vertical {
    background-color: $gray4;

    .MuiAccordionDetails-root {
      padding: 0;
      .new-inv,
      .prescription {
        margin: 12px 6px;
        width: calc(100% - 12px);
      }
    }
  }
}

.future-refill-select {
  max-width: 200px;
  font-size: 14px;
}

.caret-down {
  transform: rotate(180deg);
}

.shopping-list-two {
  min-height: 100vh;
}

.shopping-list-table {
  width: 100%;
  font-family: "Roboto Condensed", sans-serif;
  font-stretch: condensed;

  thead.thead.sticky-header tr {
    top: 179px;
  }

  > thead > tr {
    font-weight: bold;
    > th > .th-cell {
      padding-top: 14px !important;
      padding-bottom: 14px !important;
      align-items: flex-start;
      text-align: start;
      height: 80%;
    }

    > th {
      > .th-cell {
        padding-left: 14px !important;
      }
    }
  }

  > tbody > tr > td {
    > .td-cell {
      padding-top: 14px !important;
      padding-bottom: 14px !important;
      height: 80%;
    }

    &.dispensed-quantity__cell {
      > .td-cell {
        align-items: flex-start !important;
        padding-left: 10px !important;
      }
    }

    &:first-child {
      > .td-cell {
        align-items: flex-start !important;
      }
    }
    &:last-child {
      > .td-cell {
        align-items: flex-start !important;
      }
    }

    &:last-child,
    &:nth-last-child(2) {
      padding-top: 2px !important;
      padding-bottom: 2px !important;
      // background-clip: content-box;
      > .td-cell {
        background-color: rgb(50, 77, 199, 0.06);
        height: 100%;
      }
    }

    &:nth-last-child(2) {
      > .td-cell {
        padding-right: 0 !important;
        display: flex;
        border-right: none !important;
      }
    }

    &:nth-last-child(3) {
      > .td-cell {
        border-right: none !important;
      }
    }

    .dispensed-item {
      padding-left: 7px;
    }
  }

  .th-cell.future-refills__th {
    align-items: start !important;
    padding-left: 16px !important;
  }
}

.shopping-list-dropdown {
  min-width: 155px;

  > button > p {
    font-family: "Roboto Condensed", sans-serif !important;
    font-weight: bold;
    font-size: 16px;
  }
}

.radio-button-group {
  font {
    font-family: "Roboto Condensed", sans-serif !important;
  }
}

.p2-loading-screen {
  min-height: 50vh;

  .p2-loading-wrapper {
    width: 135px;
    height: 135px;
    border-radius: 75px;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: center;

    > img {
      width: 75px;
    }
  }
}
