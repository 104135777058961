/**
* ==============================================
* Shopping Table, DropDown
* ==============================================
*/

@import "./variables.scss";

.dropdown-menu-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .menu-trigger {
    display: flex;
    align-items: center;
    cursor: pointer;
    border: none;
    vertical-align: middle;
    transition: box-shadow 0.4s ease;
    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
    &:hover {
      opacity: 0.6;
    }
  }
  .menu {
    background: $white;
    border-radius: 3px;
    position: absolute;
    z-index: 999;
    top: 32px;
    right: -7px;
    min-width: 138px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: transform 0.4s ease, visibility 0.4s;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
    li {
      a {
        text-decoration: none;
        color: #333333;
        padding: 8px;
        display: block;
        text-align: left;
        font-weight: 400;
        &:hover {
          background-color: $gray4;
        }
      }
    }
    &.active {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
  }

  &.sort-dropdown {
    .menu {
      top: 26px;
      min-width: 135px;
      left: -21px;
    }
  }

  &.pack-dropdown {
    .menu {
      top: 26px;
      min-width: 90px;
      left: -10px;
      a {
        padding: 4px 8px;
      }
      li {
        &:last-of-type {
          border-top: 1px solid $gray5;
          a {
            padding: 8px;
          }
        }
      }
    }
  }

  &.inv-dropdown {
    .menu {
      top: 26px;
      min-width: 60px;
      right: -4px;
      a {
        padding: 4px 8px;
      }
    }
  }
}

.mui-dropdown {
  display: inline-flex;
  align-items: center;
  background-color: $gray4;
  padding: 6px;
  border-radius: 4px;
  > span {
    margin-right: 8px;
  }
  .MuiSelect-select {
    min-height: 27px !important;
    min-width: 25px;
    padding: 3px;
    border-radius: 4px;
    background-color: $white;
    padding-left: 4px;
    font-size: 14px !important;
    padding-right: 20px !important;
  }
  .MuiMenu-list {
    max-height: 380px !important;
    li {
      font-size: 14px !important;
    }
  }
  svg {
    top: 4px !important;
    right: 1px !important;
  }
  fieldset {
    border: 0;
  }
}

.mui-dropdown-menu {
  display: flex;
  align-items: flex-start;
  margin-top: 12px;
  &:hover {
    opacity: 0.6;
  }
  .d-label {
    display: flex;
    span {
      margin-right: 8px;
      color: $primayBlue;
      white-space: nowrap;
    }
  }
  .select {
    width: 0;
    .MuiSelect-select {
      font-size: 0;
      width: 0;
      height: 0;
    }
    .MuiMenu-list li {
      font-size: 14px !important;
    }
    svg {
      display: none;
    }
    fieldset {
      border: 0;
    }
  }
  &.disabled {
    a,
    img {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }
}

.MuiMenu-list {
  max-height: 380px !important;
}

.modal-dropDown {
  .MuiInputBase-root {
    width: 190px;
    min-width: fit-content;
  }
  .MuiSelect-select {
    border: 1px solid $gray8;
    max-width: 100%;
    width: 100% !important;
    min-width: fit-content!important;
  }
}

