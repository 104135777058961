@import "./variables.scss";

.supplierSafetyScreen {
  * {
    font-stretch: condensed;
  }

  #title {
    font-size: 20px;
  }

  h5 {
    padding-left: 8px;
    padding-right: 8px;
    font-weight: bold;
    font-size: 18px;
    color: $black;
  }

  #interact {
    margin-left: 32px;
  }

  .dot-flashing {
    position: relative;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: white;
    color: white;
    animation: dot-flashing 1s infinite linear alternate;
    animation-delay: 0.5s;
  }
  .dot-flashing::before, .dot-flashing::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }
  .dot-flashing::before {
    left: -14px;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: white;
    color: white;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 0s;
  }
  .dot-flashing::after {
    left: 14px;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: white;
    color: white;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 1s;
  }

  @keyframes dot-flashing {
    0% {
      background-color: white;
    }
    50%,100% {
      background-color: rgb(46,46,46);
    }
  }

  .having-trouble {
    .refreshIcon {
      width: 16px;
      height: 16px;
      display: inline;
      margin-left: 8px;
    }

    li {
      margin-bottom: 8px;
    }
    li::marker {
      font-weight: bold;
    }
    .action {
      text-decoration: underline;
      cursor: pointer;
      text-underline-offset: 4px;
    }
  }
}
