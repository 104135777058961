@import "./variables.scss";

.enhancedSupplierNavigation {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid $gray1;
  border-top: 1px solid $gray1;
  background-image: linear-gradient(to bottom, $gray1, #ffffff 24px);
  padding-left: 80px;
  padding-right: 80px;
  align-items: flex-start;
  .row {
    width: 100%;
    display: flex;
    margin-top: 0px;
    margin-bottom: 0px;
    align-items: center;
    justify-content: space-between;
    &:first-child {
      margin-top: 16px;
      margin-bottom: 8px;
    }
  }
  .navBarHeader {
    border-bottom: 1px solid $gray1;
  }
  .row.justify-center {
    justify-content: center;
  }
  h5 {
    padding-left: 8px;
    padding-right: 8px;
    font-weight: bold;
    font-size: 18px;
    &.breadcrumb {
      color: $primayBlue;
    }
    &.current {
      color: #8d8d8d;
      font-weight: normal;
    }
    &.action {
      color: $black;
    }
    &.sep {
      font-weight: normal;
      color: #6b7280;
      font-size: 22px;
    }
    &.login-to {
      font-size: 32px;
      color: #404040;
    }
  }
  .breadcrumbs, .actions {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }
  .actions {
    justify-content: flex-end;
    font-stretch: condensed;
  }
  .backIcon {
    width: 16px;
    height: 16px;
  }
  .homeIcon {
    color: rgba(0, 0, 0, 0.85);
    width: 20px;
    height: 20px;
  }
  .refreshIcon {
    color: rgba(0, 0, 0, 0.85);
    width: 24px;
    height: 24px;
  }
  .homeLabel {
    line-height: 24px;
    &.v2 {
      font-weight: normal;
      text-decoration: underline;
    }
  }
  .moreActionsIcon {
    width: 24px;
    height: 24px;
  }

  .ndcSearch {
    width: 100%;
    display: flex;
    align-items: center;

    * {
      margin-right: 8px;
      margin-left: 8px;

      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }

    .drugDesc {
      font-weight: bold;
      text-decoration: underline;
      cursor: pointer;
    }

    .copy {
      text-transform: uppercase;
      font-size: 14px;
      text-decoration: underline;
      cursor: pointer;
    }

    .el {
      position: relative; /* ADDED */
    }

    .el:after {
      content: '';
      position: absolute;
      border-left: 2px solid #cccccc;
      right: -10px;
      height: 80%;
    }

    .el:last-child:after {
      display: none; /* Hide the divider for the last block */
    }
  }

  .shoppingList {
    width: 100%;
    display: flex;
    align-items: center;
    * {
      margin-right: 8px;
      &:last-child {
        margin-right: 0;
      }
    }
    #options-button, #refresh-button {
      font-weight: bold;
    }
    #refresh-button {
      height: 35px;
      width: 35px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .shoppingListDropdown {
    background-color: inherit;
    .MuiInputBase-root {
      width: 100%;
      min-width: fit-content;
    }
    .MuiSelect-select {
      border: 1px solid $gray8;
      background-color: #f8f8f8;
      line-height: 0.8em;
    }
  }
  .btn-white-shade {
    background-color: #f8f8f8;
    border: 1px solid $gray8;
    border-radius: 3px;
    font-size: 14px;
    padding: 6px 16px;
    &:hover {
      background-color: $gray1;
    }
  }
}

#cartOpsSummaryModal {
  .summaryMsg {
    color: $orange;
    font-weight: bold;
  }
  .listName{
    color: #0A1E79;
  }
}

.btn.btn-white-huge {
  color: $black;
  border: 2px solid $primayBlue;
  background-color: $whiteHover;
  white-space:nowrap;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
  width: 400px;
  span {
    width: 100%;
    text-align: center;
  }
  &:hover {
    background-color: #dde4eb;
  }
}
