.future-refills {
  display: flex;
  width: 100%;

  &__svg-wrapper {
    min-width: 48px;
    min-height: 48px;
    display: flex;
    align-items: center;
  }

  &--content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &-ndc {
      display: flex;
      align-items: flex-end;
  
      &--quantity {
        white-space: nowrap;
        line-height: 1.29;
      }
      &--fills {
        white-space: nowrap;
        line-height: 1.29;
      }
    }
  }
}