/**
* ==============================================
* General Utility
* ==============================================
*/

@import "./variables.scss";
@import "./antd-overrides.scss";

@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&display=swap');
@font-face {
  font-family: "Roboto";
  src: url(https://fonts.gstatic.com/s/robotocondensed/v25/ieVl2ZhZI2eCN5jzbjEETS9weq8-19K7DQ.woff2) format("woff2");
  font-stretch: 50%;
  // font-weight: normal;
}

@font-face {
  font-family: "Roboto";
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TLBCc6CsQ.woff2) format('woff2');
  font-stretch: 200%;
  // font-weight: bold;
}

// weight
.font-200 {
  font-weight: 200;
}
.font-300 {
  font-weight: 300;
}
.font-500 {
  font-weight: 500;
}
.font-700 {
  font-weight: 700;
}
.text-xl {
  font-size: 21px;
  line-height: 1em;
}
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}
.text-xs {
  font-size: 12px;
  line-height: 1rem;
}
.text-sm {
  font-size: 14px;
  line-height: 1rem;
}
.text-24 {
  font-size: 24px;
  line-height: 1em;
}
.text-16 {
  font-size: 16px;
  line-height: normal;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.underline {
  text-decoration: underline;
}
.no-wrap {
  white-space: nowrap;
}
.cursor-pointer {
  cursor: pointer!important;
}
.block {
  display: block;
}
.sticky {
  position: sticky;
}
.z-999 {
  z-index: 999;
}
.top-0 {
  top: 0;
}

.flex {
  display: flex;
}
.flex-col {
  flex-direction: column;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: end;
}
.justify-center {
  justify-content: center;
}
.items-center {
  align-items: center;
}
.items-start {
  align-items: start;
}

// background-color
.bg-gray4 {
  background-color: $gray4;
}
.bg-white {
  background-color: $white;
}

// font color
.color-white {
  color: $white;
}
.color-gray2 {
  color: $gray2;
}
.color-primeblue {
  color: $primayBlue;
}
.color-green {
  color: #259452;
}
.color-orange {
  color: $orange;
}
.disabled {
  color: $gray6;
}

// margin
.mt-3 {
  margin-top: 3px;
}
.mt-5 {
  margin-top: 5px;
}
.mt-8 {
  margin-top: 8px;
}
.mt-12 {
  margin-top: 12px;
}
.mt-14 {
  margin-top: 14px;
}
.mt-24 {
  margin-top: 24px;
}
.mt-27 {
  margin-top: 27px;
}
.mt-48 {
  margin-top: 48px;
}
.ml-4 {
  margin-left: 4px;
}
.ml-6 {
  margin-left: 6px;
}
.ml-12 {
  margin-left: 12px;
}
.ml-14 {
  margin-left: 14px;
}
.ml-24 {
  margin-left: 24px;
}
.ml-96 {
  margin-left: 96px;
}
.mr-6 {
  margin-right: 6px;
}
.mr-8 {
  margin-right: 8px;
}
.mr-32 {
  margin-right: 32px;
}
.mr-96 {
  margin-right: 96px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-48 {
  margin-bottom: 48px;
}

// padding
.p-14 {
  padding: 14px;
}
.pt-8 {
  padding-top: 8px;
}
.pt-48 {
  padding-top: 48px;
}
.pb-8 {
  padding-bottom: 8px;
}
.pb-16 {
  padding-bottom: 16px;
}
.pb-32 {
  padding-bottom: 32px;
}
.pl-48 {
  padding-left: 48px;
}
.py-14 {
  padding-top: 14px;
  padding-bottom: 14px;
}

// border
.border-b {
  border-bottom: 1px;
  border-style: solid;
}

.hover-underline {
  &:hover {
    text-decoration: underline;
  }
}
.hover-pointer {
  &:hover {
    cursor: pointer;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.transform-90 {
  transform: rotate(90deg);
}

.nowrap {
  white-space: nowrap;
}
