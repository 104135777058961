@import "./variables.scss";

.enhancedSupplierBrowserBar {
  height: 50px;
  border-bottom: 1px solid #fff;
  padding-left: 48px;
  display: flex;
  align-items: center;

  .navBarRefreshLink:hover {
    text-decoration: underline;
  }
  .navBarRefreshLink:active {
    text-decoration: underline;
    text-decoration-thickness: 2px;
  }

  .navBarButtonOval {
    width: 28px;
    height: 28px;
    position: relative;
  }

  .navBarButtonOval.enabled {
    cursor: pointer;
  }

  .navBarButtonOval.back {
    margin-right: 16px;
  }

  .navBarButtonOval.forward {
    margin-right: 24px;
  }

  .navBarButtonOval .element {
    position: absolute;
    top: 6px;
    left: 6px;
  }

  .navBarButtonOval.enabled .element {
    color: white;
  }

  .navBarButtonOval.enabled:hover::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    background-color: #9b9b9b;
    border-radius: 13px;
  }

  .navBarButtonOval.enabled:active::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    background-color: #726c66;
    border-radius: 13px;
  }

  .navBarButtonOval.disabled .element {
    color: rgb(131, 131, 131);
    cursor: default;
  }
}
