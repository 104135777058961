@import "./variables.scss";

.loader-light {
  background: linear-gradient(to right, $gray4 4%, $white 25%, $gray4 36%);
  animation: shimmer 4s infinite linear;
  background-size: 1000px 100%;
  border-radius: 0;
}


.tbl-row {
  border-bottom: 6px solid $white;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}