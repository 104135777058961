.previousShoppingLists {
  .card {
    padding: 24px;
    margin-top: 16px;
    border-radius: 9px;
    box-shadow: 0 0 10px 0 #0000001e;
    border: solid 1px #E8E6E6;
    background-color: #ffffff;
    display: flex;
    > div {
      display: flex;
      flex-direction: column;
      padding: 24px;
    }
    > div:first-child {
      width: 45%;
      border-right: 2px solid #c7c7c7;
    }
  }

  ul.supplierOrderSummary {
    list-style-type: disc;
    padding-left: 24px;
    li::marker {
      font-size: 24px;
      color: #666666;
    }
    .supplierOrderSummary__supplierName {
      font-size: 18px;
      font-weight: 500;
    }
    .supplierOrderSummary__itemCount {
      font-size: 14px;
      padding-left: 8px;
      font-weight: 500;
    }
  }
}
