.accordion-panel {
  height: 0;
  overflow: hidden;
  padding: 0;
  visibility: hidden;
  width: 100%;
  &.open {
    height: auto;
    visibility: visible;
    transition: height .35s ease;
  }
  
}
