.horizontal-carousel {
  width: 100%;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
}
.horizontal-carousel .horizontal-carousel-nav {
  overflow-x: auto;
  overflow-y: hidden;

  max-width: 100%;
  position: relative;
}
.horizontal-carousel .horizontal-carousel-nav::-webkit-scrollbar {
  display: none;
}

.horizontal-carousel .horizontal-carousel-nav .horizontal-carousel-content {
  float: left;
  transition: transform 0.2s ease-in-out;
}

.horizontal-carousel .horizontal-carousel-nav .horizontal-carousel-content.no-transition {
  transition: none;
}

.horizontal-carousel .horizontal-nav {
  /* height: 48px; */
  height: 100%;
  position: absolute;
  top: 0;
  text-align: center;
  z-index: 1;
  cursor: default;
  width: 24px;
  opacity: 1;
  transition: opacity .3s ease-in-out;
}
.horizontal-carousel .horizontal-nav  a.nav-arrow {
  display: flex;
  /* align-items: center; */
  align-items: center;
  /* padding-top: 12px; */
  /* padding-top: 100%; */
  justify-content: center;
  text-decoration: none;
  width: 24px;
  background-color: #fff;
  height: 100%;
  transition: all 0.3s;
  cursor: pointer;
}
.horizontal-carousel .horizontal-nav  a.nav-arrow img {
  transition: all 0.3s ease-in-out;
}

.horizontal-carousel .horizontal-nav  a.nav-arrow:hover,
.horizontal-carousel .horizontal-nav  a.nav-arrow:focus,
.horizontal-carousel .horizontal-nav  a.nav-arrow:active {
  text-decoration: none;
  opacity: 1!important;
  width: 24px;
}

.horizontal-carousel .horizontal-nav a img {
  opacity: 0.5;
}

.horizontal-carousel .horizontal-nav  a.nav-arrow:hover img,
.horizontal-carousel .horizontal-nav  a.nav-arrow:focus img,
.horizontal-carousel .horizontal-nav  a.nav-arrow:active img {
  opacity: 1;
}

.horizontal-carousel .horizontal-nav.horizontal-nav-left {
  left: -1px;
  animation: 0.3s slideout forwards;
}
.horizontal-carousel .horizontal-nav.horizontal-nav-left img {
  transform: rotate(90deg);
}
.horizontal-carousel[data-overflow="both"] .horizontal-nav.horizontal-nav-left,
.horizontal-carousel[data-overflow="left"] .horizontal-nav.horizontal-nav-left {
  border-right: 1px solid #F5F5F5;
  box-shadow: 5px 0 11px -5px rgb(0 0 0 / 15%);
  transition: box-shadow .3s ease-in-out;
  clip-path: inset(0 -8px 0px 0);
  animation: 0.3s slidein;
}
.horizontal-carousel[data-overflow="both"] .horizontal-nav.horizontal-nav-left:hover,
.horizontal-carousel[data-overflow="both"] .horizontal-nav.horizontal-nav-left:focus,
.horizontal-carousel[data-overflow="both"] .horizontal-nav.horizontal-nav-left:active,
.horizontal-carousel[data-overflow="left"] .horizontal-nav.horizontal-nav-left:hover,
.horizontal-carousel[data-overflow="left"] .horizontal-nav.horizontal-nav-left:focus,
.horizontal-carousel[data-overflow="left"] .horizontal-nav.horizontal-nav-left:active {
  box-shadow: 5px 0 11px -5px rgb(0 0 0 / 45%);
}

.horizontal-carousel .horizontal-nav.horizontal-nav-right {
  right: 0;
  /* opacity: 0; */
  animation: 0.3s slideout forwards;
}
.horizontal-carousel .horizontal-nav.horizontal-nav-right img {
  transform: rotate(-90deg);
}
.horizontal-carousel[data-overflow="both"] .horizontal-nav.horizontal-nav-right,
.horizontal-carousel[data-overflow="right"] .horizontal-nav.horizontal-nav-right{
  border-left: 1px solid #F5F5F5;
  box-shadow: -5px 0 11px -5px rgb(0 0 0 / 15%);
  transition: box-shadow .3s ease-in-out;
  clip-path: inset(0 -3px 0px -8px);
  animation: 0.3s slidein;
}
.horizontal-carousel[data-overflow="both"] .horizontal-nav.horizontal-nav-right:hover,
.horizontal-carousel[data-overflow="both"] .horizontal-nav.horizontal-nav-right:focus,
.horizontal-carousel[data-overflow="both"] .horizontal-nav.horizontal-nav-right:active,
.horizontal-carousel[data-overflow="right"] .horizontal-nav.horizontal-nav-right:hover,
.horizontal-carousel[data-overflow="right"] .horizontal-nav.horizontal-nav-right:focus,
.horizontal-carousel[data-overflow="right"] .horizontal-nav.horizontal-nav-right:active {
  box-shadow: -5px 0 11px -5px rgb(0 0 0 / 45%);
}

@keyframes slidein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes slideout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes slidein-left {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideout-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
@keyframes slidein-right {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideout-right {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}