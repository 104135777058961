@import "./variables.scss";
.table {
  position: relative;
  .thead.sticky-header > tr {
    position: sticky;
    top: 200px;
    z-index: 999;
    overflow-anchor: none;
    border-bottom: 1px solid #d5d5d5
  }

  &.table--primary {
    border-spacing: 0;
  
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
  
    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #e5e5e5;
      border-right: 1px solid #e5e5e5;
      border-left: 1px solid #e5e5e5;  
    }
  
    th {
      background-color: #e8e8e8;
      border-bottom: 1px solid $white;
      border-right: 1px solid $white;
  
      &:last-child {
        border-right: 1px solid #e5e5e5;
      }
    }
  
    &.exception {
      height: 40%;
      > .tbody > .tr-exception {
        background: $gray4;
  
        > .td-exception {
          background: $gray4;  
          border: none
        }
      }
    }
  }
  
  &.table--custom {
    border-collapse: separate;
    font-size: 14px;
    border-spacing: 0 2px;
  
    > .thead > tr {
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      height: 1px;
      border-top-right-radius: 9px;
      border-top-left-radius: 9px;
  
      > th {
        padding: 7px 0;
        border-bottom: 2px solid $white;
        background: $white;
        height: inherit;
  
        > .th-cell {
          border-right: 1px solid #e5e5e5;
          padding: 5px 4px;
          width: 100%;
          display: flex;
          justify-content: center;
          flex-direction: column;
        }
  
        &:first-child {
          border-top-left-radius: 9px;
        }
  
        &:last-child {
          border-top-right-radius: 9px;
          .th-cell {
            border-right: none;
          }
        }
      }
    }
  
    > .tbody > tr {
      background: $white;
      height: 1px;

      &.separator {
        height: 3px;
      }
  
      > td {
        border: solid 1px #d5d5d5;
        border-style: solid none;
        padding: 5px 0;
        background-color: $white;
        height: inherit;
  
        > .td-cell {
          border-right: 1px solid #e5e5e5;
          padding: 4px 7px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
        }
      }
  
      > td:first-child {
          border-left-style: solid;
          border-top-left-radius: 3px; 
          border-bottom-left-radius: 3px;
      }
      > td:last-child {
          border-right-style: solid;
          border-bottom-right-radius: 3px; 
          border-top-right-radius: 3px; 
      }
    }
  
    &.exception {
      height: 40%;
      > .tbody > .tr-exception {
        background: $gray4;
  
        > .td-exception {
          background: $gray4;  
          border: none
        }
      }
    }
  }  
}
