/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
 .dot-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 1rem 0;
  margin: 0 -5%;
  overflow: hidden;
 }
 .dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #C9C9C9;
  color: #C9C9C9;
  -webkit-animation: dot-flashing 1s infinite linear alternate;
  animation: dot-flashing 1s infinite linear alternate;
  -webkit-animation-delay: .5s;
  animation-delay: .5s;
}

.dot-flashing::before, .dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #C9C9C9;
  color: #C9C9C9;
  -webkit-animation: dot-flashing 1s infinite alternate;
  animation: dot-flashing 1s infinite alternate;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #C9C9C9;
  color: #C9C9C9;
  -webkit-animation: dot-flashing 1s infinite alternate;
  animation: dot-flashing 1s infinite alternate;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

@-webkit-keyframes dot-flashing {
  0% {
    background-color: #C9C9C9;
  }
  50%,
  100% {
    background-color: #e6e6e9;
  }
}

@keyframes dot-flashing {
  0% {
    background-color: #C9C9C9;
  }
  50%,
  100% {
    background-color: #e6e6e9;
  }
}