@import "./base.scss";

.purchase-quantity {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  border-right: 2px solid white;
  padding-left: 4px;

  &__dropdown {
    background-color: transparent;
    border: none;
  }

  .input--primary {
    background: #fff;
    border-radius: 6px;
    padding: 3px 11px;
    max-width: 60px;
    text-align: center;

    &:focus {
      border: none;
      outline: none;
    }
  }

  .mui-dropdown {
    .MuiInputBase-root {
      .MuiSelect-select {
        min-width: 25px;
        text-align: center;
        border: none;
      }
    }
  }

  .total-num {
    font-weight: 700;
  }
}