/**
* ==============================================
* Shopping Table, Slot Button
* ==============================================
*/

@import "./variables.scss";

.slot-container {
  display: flex;
  height: 40px;
  overflow: hidden;

  .button-container {
    height: 40px;
    display: flex;
    flex-direction: column;
  }
}

  /* Animation Effects */
  .slideDown1 {
    top: 40;
    animation: slideDown1 ease 0.4s;
    animation-fill-mode: forwards;
  }

  .slideUp1 {
    top: 0;
    animation: slideUp1 ease 0.4s;
    animation-fill-mode: forwards;
  }

  .slideUp2 {
    animation: slideUp2 ease 0.4s;
    animation-fill-mode: forwards;
  }

  .slideUp3 {
    animation: slideUp3 ease 0.4s;
    animation-fill-mode: forwards;
  }

@keyframes slideUp1 {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-40px);
  }
}

@keyframes slideUp2 {
  0% {
    transform: translateY(-40);
  }
  100% {
    transform: translateY(-80px);
  }
}

@keyframes slideUp3 {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-120px);
  }
}

@keyframes slideDown1 {
  0% {
    transform: translateY(-40px);
  }
  100% {
    transform: translateY(0);
  }
}
